import { globalComAxios, globalWithOutGidComAxios } from './axiosConfig';

// 同步
export function apiGlobalSynOrder (params: any) {
	return globalComAxios('/api/ext/data/synOrder', params, true, true);
}

// 获取全球苹果小票
export function apiServiceGetGlobalReceipt (params: any) {
	return globalComAxios('/api/ext/data/getAppleReceipts', params);
}

// 解绑用户
export function apiGlobalUnbindUser (params: any) {
	return globalWithOutGidComAxios('/api/ext/data/unbindUser', params, true, true);
}

// 重置密码
export function apiGlobalResetPwd (params: any) {
	return globalWithOutGidComAxios('/api/ext/data/resetPwd', params, true, true);
}

// 无效订单查询
export function apiGlobalRefundOrderList (params: any) {
	return globalComAxios('/api/ext/data/voidederOrder', params);
}

// 订单列表
export function apiGlobalGetOrder (params: any) {
	return globalComAxios('/api/ext/data/orderDetailRecords', params);
}

// 补单
export function apiGlobalRepairOrder (params: any) {
	return globalComAxios('/api/ext/data/repairOrder', params, true, true);
}

// 补单明细
export function apiGlobalGetRepairOrderLog (params: any) {
	return globalComAxios('/api/ext/data/supplementDetailRecords', params);
}
