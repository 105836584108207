import { apiAppGameList } from '@/api/app';
export default (() => {
  const gameList = ref([]);
  const getGameList = async () => {
    const res = await apiAppGameList({
      publish_status: 1,
      status: 1
    });
    if (res.code === 0) {
      gameList.value = res.data;
    }
  };
  return {
    gameList,
    getGameList
  };
});